.add-content-playlist span {
    padding-left: 6px;
    font-size: 14px;
}

.delete-playlist span{
    padding-right: 6px;
    font-size: 14px;
}
.multiple--assign--btn:hover{
   background-color: var(--primary-color) !important;
}