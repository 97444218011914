.container {
    min-height: 100vh;
    max-width: 100vw;
    background-image: url("/public/images/login-background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.eclips-1 {
    position: absolute;
    width: 84px;
    height: 84px;
    left: 650px;
    top: 63px;
    border-radius: 50%;
    background: #FF1000;
}

.eclips-2 {
    position: absolute;
    width: 52px;
    height: 52px;
    left: 1330px;
    top: 490px;
    border-radius: 50%;
    background: #FF1000;
}

.eclips-3 {
    position: absolute;
    width: 400px;
    height: 400px;
    left: 1250px;
    top: -124px;
    border-radius: 50%;
    background: #FF1000;
}

.inner-container {
    /* height: 100vh;
    width: 100vw; */
    border:4px solid black;
    position: absolute;
    display: flex;
    flex-direction: column;

}

.inner-container .top-box {
    height: 90vh;
    width: 100vw;
    /* border:1px solid green; */
    position: relative;
    display: flex;
}

.login-box {
    /* position: absolute; */
    height: 100%;
    width: 100%;
    /* border:1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    height: 75vh;
    width: 400px;
    background: #FFFFFF;
    border-radius: 25px;
    border:2px solid var(--primary-color);
    /* box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.3),
        -1px -1px 0 0 rgba(0, 0, 0, 0.3); */
    display: flex;
    flex-direction: column;
}

.forget-password-form {
    height: 400px;
    width: 400px;
    background: #FFFFFF;
    border-radius: 25px;
    border:2px solid var(--primary-color);
    /* box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.3),
        -1px -1px 0 0 rgba(0, 0, 0, 0.3); */
    display: flex;
    flex-direction: column;
}

.login-form-header {
    height: 13vh;
    width: 100%;
    border-bottom: 3px solid var(--primary-color);
    ;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .login-form-header .logo-sec {
    display: flex;
    justify-content: center;
    align-items: center;
} */

/* .logo-sec .logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 10px;
} */

.logo-sec .logo img {
    height: 100px;
    width: 100px;
    object-fit: contain;
}

.logo-sec .logo-text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.02em;
    color: var(--primary-color);
    text-transform: uppercase;
}

.login-form-body {
    height: 100vh;
    width: 100%;
    /* border:1px solid black; */
    display: flex;
    flex-direction: column;
}

.login-form-body .title {
    height: 15%;
    width: 100%;
    display: flex;
    margin: 20px 0;
}

.login-icon {
    width: 36px;
    height: 39px;
    background: var(--primary-color);
    border-radius: 0px 21px 21px 0px;
}

.title-text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    /* font-size: 40px; */
    line-height: 54px;
    letter-spacing: -0.02em;
    color: #313031;
    margin-top: -7px;
    margin-left: 10px;

}
.size-title-text-1{
    font-size: 40px;
}
.size-title-text-2{
    font-size: 30px;
}
.input-fields {
    height: 15%;
    width: 80%;
    margin: 5px auto;
    /* border:1px solid black; */
}

.button-field {
    height: 15%;
    width: 80%;
    margin: 25px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border:1px solid black; */
}

.resend-email {
    height: 10%;
    width: 80%;
    margin: 0 auto;
    transform: translateY(67px);
    /* border:1px solid black; */
    font-size: 18px;
}

.other-controlls {
    height: 13%;
    width: 80%;
    margin: 2px auto;
    /* border:1px solid black; */
    display: flex;
    justify-content: space-between;
}

.other-controlls .checkbox-div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #313031;
    font-size: 15px;
    /* gap:2px; */
}

.other-controlls a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #313031;
    text-decoration: none;
}

.button-field .btn-login {
    height: 40px;
    width: 150px;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.text-box {
    height: 100%;
    width: 50%;
    /* border:5px solid green;  */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.text-block {
    height: 500px;
    width: 400px;
}

.main-heading {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    letter-spacing: -0.02em;
    color: #313031;
    margin-bottom: 10px;
}

.sub-heading {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    color: #92959E;
}

.inner-container .bottom-box {
    height: 10vh;
    width: 100vw;
    border-top: 5px solid var(--primary-color);
    background: #FFFFFF;
    opacity: 0.7;
}

.password-field {
    position: relative;
}

.show-password {
    position: absolute;
    bottom: 310px;
    left: 510px;
    color: var(--primary-color);
    cursor: pointer;
}

/* @media only screen and (min-width:1900px) {
    .login-form {
        width: 595px;
        height: 795px;
        margin-top: 30px;
        margin-left: -60px;
    }

    .forget-password-form {
        width: 400px;
        height: 500px;
        margin-top: 30px;
        margin-left: -60px;
    }

    .login-form-header {
        height: 15vh;
    }

    .eclips-1 {
        position: absolute;
        left: 800px;
        top: 60px;
    }

    .eclips-2 {
        position: absolute;
        left: 1700px;
        top: 690px;
    }

    .eclips-3 {
        position: absolute;
        left: 1623px;
        top: -124px;
    }

    .logo-sec .logo {
        height: 150px;
        width: 150px;
    }

    .show-password {
        position: absolute;
        bottom: 460px;
        left: 620px;
    }
}

@media only screen and (max-width:1700px) {
    .show-password {
        position: absolute;
        left: 470px;
    }

    .eclips-1 {
        position: absolute;
        left: 600px;
        top: 60px;
    }

    .eclips-2 {
        position: absolute;
        left: 1330px;
        top: 490px;
    }

    .eclips-3 {
        position: absolute;
        left: 1323px;
        top: -124px;
    }

    .logo-sec .logo {
        height: 100px;
        width: 100px;
    }
} */

@media only screen and (max-width:992px) {
    .show-password {
        position: absolute;
        left: 350px;
    }

    .logo-sec .logo {
        height: 100px;
        width: 100px;
    }
}

@media only screen and (max-width:768px) {
    .inner-container .top-box {
        height: 90vh;
        width: 100vw;
        /* border:1px solid green; */
        position: relative;
        display: flex;
        flex-direction: row;
    }

    .show-password {
        position: absolute;
        left: 300px;
    }

    .logo-sec .logo {
        height: 100px;
        width: 100px;
    }

    .text-block {
        display: none;
    }
}

@media only screen and (max-width:460px) {
    .container {
        min-height: 100vh;
        width: 100vw;
    }

    .other-controlls a,
    .checkbox-div {
        font-size: 10px;
    }

    .inner-container .top-box {
        height: 90vh;
        width: 100vw;
        /* border:1px solid green; */
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo-sec .logo {
        height: 100px;
        width: 100px;
    }

    .login-box {
        /* position: absolute; */
        height: 100%;
        width: 90%;
        /* border:1px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-box {
        display: none;
    }

    .eclips-1 {
        display: none;
    }

    .eclips-2 {
        display: none;
    }

    .eclips-3 {
        display: none;
    }

    .password-field {
        position: relative;
    }

    .input-fields .show-password {
        position: absolute;
        left: 270px;
        bottom: 299px;
    }
}

/* extra large screens */
/* @media only screen and (min-width:1200px){
    .login-form{
        width: 595px;
height: 795px;
margin-top: 30px;
margin-left: -60px;
    }
    .login-form-header{
        height:15vh;
    }
    .eclips-1{
        position: absolute;
        left: 781px;
top: 63px;
    }
    .eclips-2{
        position: absolute;
        left: 1682px;
top: 790px;
    }
    .eclips-3{
        position: absolute;
        left: 1623px;
top: -124px;
    }
    .show-password{
        position: absolute;
        bottom: 460px;
        left:620px;
    }
} */
/* for large screens */
/* @media only screen and(max-width:992px){
    .eclips-1 {
        position: absolute;
        width: 84px;
        height: 84px;
        left: 650px;
        top: 63px;
        border-radius: 50%;
         background: #551fff; 
        background: #313031;
      }
      .eclips-2 {
        position: absolute;
        width: 52px;
        height: 52px;
        left:1330px;
        top:490px;
        border-radius: 50%;
        /* background: #551fff; 
        background-color: #313031;  
    }
      .eclips-3 {
        position: absolute;
        width: 400px;
        height: 400px;
        left: 1250px;
        top: -124px;
        border-radius: 50%;
        background: #551fff;
      }
      .login-form{
        height:500px;
        width:400px;
      }
} */
/*  */